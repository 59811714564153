import { Text, Link, Spinner, Combobox, GroupedCombobox } from "@hightouchio/ui";

import { DbtJobsQuery, useDbtAccountsQuery, useDbtCredentialsQuery, useDbtJobsQuery } from "src/graphql";
import { Field } from "src/ui/field";

import { ScheduleManagerProps } from "./schedule-manager";
import { DbtCloudSchedule } from "./types";

interface DbtCloudScheduleProps extends ScheduleManagerProps {
  schedule: DbtCloudSchedule;
}

export const ConfigureDbtCloudSchedule = ({ schedule, setSchedule }: DbtCloudScheduleProps) => {
  const { data: dbtCredentials, isLoading: loadingCredentials } = useDbtCredentialsQuery(undefined, {
    select: (data) => data.dbt_credentials[0],
  });

  const { isLoading: loadingAccounts, data: accountData } = useDbtAccountsQuery(undefined, {
    enabled: Boolean(dbtCredentials?.id),
  });

  const { isLoading: loadingJobs, data: jobData } = useDbtJobsQuery(
    {
      accountId: schedule?.schedule?.account?.id ?? "",
    },
    { enabled: Boolean(dbtCredentials?.id && schedule?.schedule?.account?.id) },
  );

  const accounts = accountData?.getDbtCloudAccounts || [];
  const jobs = jobData?.getDbtCloudJobs || [];

  const transformDbtJobs = (
    dbtJobs: DbtJobsQuery["getDbtCloudJobs"],
  ): { groupedJobs: { label: string; options: { value: string; label: string }[] }[]; jobIdToName: Record<string, string> } => {
    const groupedJobs: Record<string, { label: string; options: { value: string; label: string }[] }> = {};
    const jobIdToName: Record<string, string> = {};

    for (const job of dbtJobs) {
      const projectName = job.project?.name || "Root";

      if (!groupedJobs[projectName]) {
        groupedJobs[projectName] = {
          label: projectName,
          options: [],
        };
      }

      const environmentName = job.environment?.name || "";
      const displayName = `${job.name} (${environmentName})`;
      const option = {
        value: job.id,
        label: displayName,
      };

      jobIdToName[job.id] = displayName;
      groupedJobs[projectName]?.options?.push(option);
    }

    return { groupedJobs: Object.values(groupedJobs), jobIdToName };
  };

  const { groupedJobs, jobIdToName } = transformDbtJobs(jobs);

  if (loadingCredentials) {
    return <Spinner />;
  }
  if (!dbtCredentials) {
    return (
      <Text>
        <Link href="/extensions/dbt-cloud/configuration">Add your dbt credentials</Link> before continuing.
      </Text>
    );
  }
  return (
    <>
      <Field label="Account">
        <Combobox
          isLoading={loadingAccounts}
          options={
            accounts.map((account) => ({
              label: account.name,
              id: account.id,
              account,
            })) as { label: string; id: string; account?: any }[]
          }
          placeholder="Select an account"
          optionLabel={(option) => option.label}
          optionValue={(option) => ({ label: option.label, id: option.id })}
          value={
            schedule?.schedule?.account?.id && {
              label: schedule?.schedule?.account?.name,
              id: schedule?.schedule?.account?.id,
            }
          }
          width="md"
          onChange={(selected) => {
            if (!selected) {
              return;
            }
            setSchedule({
              ...schedule,
              schedule: {
                ...schedule?.schedule,
                dbtCredentialId: dbtCredentials.id,
                account: {
                  id: selected.id,
                  name: selected.label,
                },
              },
            });
          }}
        />
      </Field>

      <Field label="Job">
        <GroupedCombobox
          isLoading={loadingJobs}
          width="md"
          placeholder="Select a job"
          optionGroups={groupedJobs}
          value={schedule?.schedule?.job?.id}
          onChange={(selected) => {
            if (!selected) {
              return;
            }

            const jobName = jobIdToName[selected] || "unknown";

            setSchedule({
              ...schedule,
              schedule: {
                ...schedule?.schedule,
                dbtCredentialId: dbtCredentials.id,
                job: {
                  id: selected,
                  name: jobName,
                },
              },
            });
          }}
        />
      </Field>
    </>
  );
};
